import { FontMetrics, createStyleObject } from '@capsizecss/core';
import iBMPlexMonoMetrics from '@capsizecss/metrics/iBMPlexMono';
import iBMPlexSansMetrics from '@capsizecss/metrics/iBMPlexSans';
import { defineStyle } from '@chakra-ui/react';

import { metrifyMetrics } from '@ui-kit/assets/metrics';

const makeCapsizeStyles = (
  fontSize: number,
  leading: number,
  fontMetrics: FontMetrics,
) => {
  const style = createStyleObject({
    fontSize,
    leading,
    fontMetrics,
  });

  return {
    fontSize: style.fontSize,
    lineHeight: style.lineHeight,
    '&:before': style['::before'],
    '&:after': style['::after'],
  };
};

const makeCapsizeOneLineStyles = (
  fontSize: number,
  leading: number,
  _fontMetrics: FontMetrics,
) => {
  return {
    fontSize,
    lineHeight: `${leading}px`,
  };
};

export const textStyles = defineStyle({
  'heading-large-4xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeStyles(80, 96, metrifyMetrics),
  },
  'heading-large-3xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeStyles(50, 60, metrifyMetrics),
  },
  'heading-large-2xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.01em',
    ...makeCapsizeStyles(30, 36, metrifyMetrics),
  },
  'heading-large-xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(25, 30, metrifyMetrics),
  },
  'heading-large-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(20, 24, iBMPlexSansMetrics),
  },
  'heading-large-md': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'heading-large-sm': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(14, 16.8, metrifyMetrics),
  },
  'heading-small-4xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeStyles(50, 60, metrifyMetrics),
  },
  'heading-small-3xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeStyles(40, 48, metrifyMetrics),
  },
  'heading-small-2xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeStyles(30, 36, metrifyMetrics),
  },
  'heading-small-xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(20, 24, metrifyMetrics),
  },
  'heading-small-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'heading-small-md': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'text-regular-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'text-regular-sm': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'text-regular-xs': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(12, 14.4, iBMPlexSansMetrics),
  },
  'text-regular-xxs': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.02em',
    ...makeCapsizeStyles(10, 12, iBMPlexSansMetrics),
  },
  'text-medium-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'text-medium-sm': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'text-medium-xs': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '500',
    letterSpacing: '-0.02em',
    ...makeCapsizeStyles(12, 16.8, iBMPlexSansMetrics),
  },
  'text-semibold-sm': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'text-regular-mono-lg': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '-0.04em',
  },
  'text-regular-mono-xl': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(35, 42, iBMPlexMonoMetrics),
  },
  'text-regular-mono-sm': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(14, 16.8, iBMPlexMonoMetrics),
  },
  'text-regular-mono-xs': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(12, 14.4, iBMPlexMonoMetrics),
  },
  'text-regular-mono-xxs': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    letterSpacing: '-0.02em',
    ...makeCapsizeStyles(10, 12, iBMPlexMonoMetrics),
  },
  'text-medium-mono-lg': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '-0.04em',
  },
  'text-medium-mono-sm': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(14, 16.8, iBMPlexMonoMetrics),
  },
  'text-medium-mono-xs': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeStyles(12, 16.8, iBMPlexMonoMetrics),
  },
});

export const textStylesOneLine = defineStyle({
  'one-line-heading-large-4xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeOneLineStyles(80, 96, metrifyMetrics),
  },
  'one-line-heading-large-3xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeOneLineStyles(50, 60, metrifyMetrics),
  },
  'one-line-heading-large-2xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.01em',
    ...makeCapsizeOneLineStyles(30, 36, metrifyMetrics),
  },
  'one-line-heading-large-xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(25, 30, metrifyMetrics),
  },
  'one-line-heading-large-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(20, 24, iBMPlexSansMetrics),
  },
  'one-line-heading-large-md': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'one-line-heading-large-sm': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(14, 16.8, metrifyMetrics),
  },
  'one-line-heading-small-4xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeOneLineStyles(50, 60, metrifyMetrics),
  },
  'one-line-heading-small-3xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeOneLineStyles(40, 48, metrifyMetrics),
  },
  'one-line-heading-small-2xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.03em',
    ...makeCapsizeOneLineStyles(30, 36, metrifyMetrics),
  },
  'one-line-heading-small-xl': {
    fontFamily: 'var(--font-metrify)',
    fontWeight: '700',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(20, 24, metrifyMetrics),
  },
  'one-line-heading-small-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'one-line-heading-small-md': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'one-line-text-regular-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'one-line-text-regular-sm': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'one-line-text-regular-xs': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(12, 14.4, iBMPlexSansMetrics),
  },
  'one-line-text-regular-xxs': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '400',
    letterSpacing: '-0.02em',
    ...makeCapsizeOneLineStyles(10, 12, iBMPlexSansMetrics),
  },
  'one-line-text-medium-lg': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(16, 19.2, iBMPlexSansMetrics),
  },
  'one-line-text-medium-sm': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'one-line-text-medium-xs': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '500',
    letterSpacing: '-0.02em',
    ...makeCapsizeOneLineStyles(12, 16.8, iBMPlexSansMetrics),
  },
  'one-line-text-semibold-sm': {
    fontFamily: 'var(--font-ibm-sans)',
    fontWeight: '600',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(14, 16.8, iBMPlexSansMetrics),
  },
  'one-line-text-regular-mono-lg': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '-0.04em',
  },
  'one-line-text-regular-mono-sm': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(14, 16.8, iBMPlexMonoMetrics),
  },
  'one-line-text-regular-mono-xs': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(12, 14.4, iBMPlexMonoMetrics),
  },
  'one-line-text-regular-mono-xxs': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '400',
    letterSpacing: '-0.02em',
    ...makeCapsizeOneLineStyles(10, 12, iBMPlexMonoMetrics),
  },
  'one-line-text-regular-medium-lg': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '-0.04em',
  },
  'one-line-text-regular-medium-sm': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(14, 16.8, iBMPlexMonoMetrics),
  },
  'one-line-text-regular-medium-xs': {
    fontFamily: 'var(--font-ibm-mono)',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    ...makeCapsizeOneLineStyles(12, 16.8, iBMPlexMonoMetrics),
  },
});
