import {
  StyleFunctionProps,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

import {
  buttonColorSchemeSolid,
  buttonColorSchemesOutline,
} from '../foundations/button-color-scheme';

const checkColorSchemeSolid = (props: StyleFunctionProps) => {
  const { colorScheme } = props;

  if (
    buttonColorSchemeSolid[
      colorScheme as keyof typeof buttonColorSchemeSolid
    ] === undefined
  ) {
    return {};
  }

  const styles =
    buttonColorSchemeSolid[colorScheme as keyof typeof buttonColorSchemeSolid];

  return styles;
};

const solid = defineStyle(checkColorSchemeSolid);

const outline = defineStyle((props: StyleFunctionProps) => {
  const { colorScheme } = props;

  const outlineStyles = {
    bg: 'transparent',
    color: 'text&icon.tx-contrast',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'stroke.st-secondary',
    _hover: {
      borderColor: 'stroke.st-contrast',
      bg: 'transparent',
    },
    _focus: {
      bg: 'transparent',
      borderColor: 'stroke.st-contrast',
    },
    _active: {
      bg: 'transparent',
      borderColor: 'stroke.st-contrast',
    },
    _disabled: {
      color: 'states.text&icon.disabled-contrast',
    },
    _loading: {
      bg: 'transparent',
      color: 'text&icon.tx-contrast',
    },
  };

  // if (
  //   buttonColorSchemesOutline[
  //     colorScheme as keyof typeof buttonColorSchemesOutline
  //   ] === undefined
  // ) {
  return outlineStyles;
  // }

  const styles =
    buttonColorSchemesOutline[
      colorScheme as keyof typeof buttonColorSchemesOutline
    ];

  return { ...outlineStyles, ...styles };
});

const sm = defineStyle({
  h: '40px',
  minW: '40px',
  p: '0 4px',
});

const md = defineStyle({
  h: '48px',
  minW: '48px',
  p: '0 10px',
});

const lg = defineStyle({
  h: '60px',
  minW: '60px',
  p: '0 18px',
});

const tab = defineStyle({
  background: 'transparent',
  color: 'text&icon.tx-secondary',

  _dark: {
    background: 'transparent',
    color: 'text&icon.tx-secondary',
  },

  _active: {
    background: 'background.bg-accent',
    color: 'text&icon.tx-primary',
  },

  _hover: {
    background: 'states.background.accent-hover',
    color: 'text&icon.tx-primary',
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: 32,
    textStyle: 'text-medium-lg',
    fontWeight: '500',

    _loading: {
      fontSize: '20px',
      opacity: 1,
    },
  },
  variants: { solid, outline, tab },
  sizes: { sm, md, lg },
});
