import {
  Avatar as AvatarChakra,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { FC } from 'react';

import { Avatar } from '../../atoms/avatar';

type Props = {
  avatarUrl: string | null;
  profile: {
    fullName: string | null;
    nickName: string | null;
  } | null;
  onSignOut: () => void;
};

export const MenuHeaderDesktop: FC<Props> = ({
  avatarUrl,
  profile,
  onSignOut,
}) => {
  const t = useTranslations();

  return (
    <Menu>
      <MenuButton
        as={AvatarChakra}
        colorScheme='pink'
        cursor='pointer'
        size='sm'
        src={avatarUrl ?? undefined}
      />
      <MenuList
        p='1px'
        bg='transparent'
        layerStyle='gradient-border-update'
        border='none'
        rounded='20px'
        overflow='hidden'
      >
        <Box
          bg='background.bg-primary'
          rounded='20px'
          overflow='hidden'
        >
          <Flex
            gap='10px'
            p='20px'
            mb={'20px'}
            align='center'
          >
            <Avatar
              size='sm'
              src={avatarUrl ?? undefined}
            />

            <Flex
              direction='column'
              maxW='230px'
            >
              <Text
                textStyle='one-line-text-medium-lg'
                noOfLines={1}
              >
                {profile?.fullName}
              </Text>
              <Text
                textStyle='one-line-text-medium-sm'
                noOfLines={1}
                color='text&icon.tx-secondary'
              >
                {profile?.nickName}
              </Text>
            </Flex>
          </Flex>

          <Box
            px='8px'
            pb='10px'
          >
            <MenuItem
              as='a'
              bg='background.bg-primary'
              p='15px 12px'
              href='/profile'
            >
              <Text textStyle='text-medium-lg'>
                {t('MenuHeaderDesktop.myProfile')}
              </Text>
            </MenuItem>
            <MenuItem
              as='a'
              p='15px 12px'
              bg='background.bg-primary'
              href='/profile/subscriptions'
            >
              <Text textStyle='text-medium-lg'>
                {t('MenuHeaderDesktop.subscriptions')}
              </Text>
            </MenuItem>
            <MenuItem
              as='a'
              p='15px 12px'
              bg='background.bg-primary'
              href='/profile/settings'
            >
              <Text textStyle='text-medium-lg'>
                {t('MenuHeaderDesktop.settings')}
              </Text>
            </MenuItem>
            <MenuItem
              p='15px 12px'
              bg='background.bg-primary'
              mt='20px'
              onClick={onSignOut}
            >
              <Text textStyle='text-medium-lg'>
                {t('MenuHeaderDesktop.logout')}
              </Text>
            </MenuItem>
          </Box>
        </Box>
      </MenuList>
    </Menu>
  );
};
