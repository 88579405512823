import { ThemeOverride, extendTheme } from '@chakra-ui/react';

import { drawerScheme } from '@/shared/ui-kit/theme/components/drawer';
import { colors } from '@/shared/ui-kit/theme/foundations/colors';
import { fontSizes } from '@/shared/ui-kit/theme/foundations/font-sizes';
import { fontWeights } from '@/shared/ui-kit/theme/foundations/font-weights';
import { fonts } from '@/shared/ui-kit/theme/foundations/fonts';
import { layerStyles } from '@/shared/ui-kit/theme/foundations/layer-styles';
import { letterSpacings } from '@/shared/ui-kit/theme/foundations/letter-spacings';
import { lineHeights } from '@/shared/ui-kit/theme/foundations/line-heights';
import {
  textStyles,
  textStylesOneLine,
} from '@/shared/ui-kit/theme/foundations/text-styles';
import { semanticTokens } from '@/shared/ui-kit/theme/semantic-tokens';
import { globalStyles } from '@/shared/ui-kit/theme/styles';

import { accordionTheme } from './components/accordion';
import { alertTheme } from './components/alert';
import { avatarTheme } from './components/avatar';
import { breadcrumbScheme } from './components/breadcrumb';
import { buttonTheme } from './components/button';
import { checkboxTheme } from './components/checkbox';
import { inputTheme } from './components/input';
import { modalTheme } from './components/modal';
import { radioTheme } from './components/radio';
import { selectTheme } from './components/select';
import { switchTheme } from './components/switch';
import { tabsTheme } from './components/tabs';
import { tagTheme } from './components/tag';
import { textareaTheme } from './components/textarea';

const overrides = (body: string, heading: string, mono: string) =>
  ({
    styles: {
      global: globalStyles,
    },
    colors,
    fontSizes,
    fontWeights,
    fonts: fonts(body, heading, mono),
    letterSpacings,
    lineHeights,
    semanticTokens: semanticTokens as any,
    layerStyles,
    textStyles: {
      ...textStyles,
      ...textStylesOneLine,
    },
    sizes: {
      container: {
        md: '1024px',
        lg: '1440px',
        xl: '1920px',
      },
    },
    breakpoints: {
      base: '0px',
      sm: '320px',
      md: '1024px',
      lg: '1440px',
      xl: '1920px',
      '2xl': '3840px',
    },
    components: {
      Select: selectTheme,
      Button: buttonTheme,
      Input: inputTheme,
      Checkbox: checkboxTheme,
      Radio: radioTheme,
      Avatar: avatarTheme,
      Alert: alertTheme,
      Tabs: tabsTheme,
      Tag: tagTheme,
      Switch: switchTheme,
      Breadcrumb: breadcrumbScheme,
      Drawer: drawerScheme,
      Accordion: accordionTheme,
      Textarea: textareaTheme,
      Modal: modalTheme,
    },
  }) satisfies ThemeOverride;

export const demuzoTheme = (body: string, heading: string, mono: string) =>
  extendTheme(overrides(body, heading, mono));

export default demuzoTheme;
