'use client';
import { Image, Link } from '@chakra-ui/next-js';
import { Box, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import Footer from '@/shared/ui-kit/molecules/footer/footer';
import IconInst from '@ui-kit/icons/icon-inst';
import IconSocialLinkedin from '@ui-kit/icons/icon-social-linkedin';
import IconSocialTiktok from '@ui-kit/icons/icon-social-tiktok';
import IconSocialX from '@ui-kit/icons/icon-social-x';
import IconSocialYoutube from '@ui-kit/icons/icon-social-youtube';

export const DemuzoFooterLeftLinks = () => {
  const t = useTranslations();

  return (
    <>
      <Link
        prefetch={false}
        href='/campaigns'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterLeftLinks.allCampaigns')}
        </Text>
      </Link>
      <Link
        prefetch={false}
        href='mailto:support@demuzo.com'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterLeftLinks.support')}
        </Text>
      </Link>
      <Link
        prefetch={false}
        href='/faq'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterLeftLinks.faq')}
        </Text>
      </Link>
      <Link
        prefetch={false}
        href='mailto:support@demuzo.com?subject=Request for partnership'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterLeftLinks.partnershipRequest')}
        </Text>
      </Link>
      <Link
        prefetch={false}
        href='/contact-us'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterLeftLinks.contactUs')}
        </Text>
      </Link>
      <Link
        prefetch={false}
        href='/news'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterLeftLinks.news')}
        </Text>
      </Link>
    </>
  );
};

export const DemuzoFooterRightLinks = () => {
  const t = useTranslations();
  return (
    <>
      <Link
        prefetch={false}
        href='/privacy/terms-of-service'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterRightLinks.termsOfUse')}
        </Text>
      </Link>
      <Link
        prefetch={false}
        href='/privacy/privacy-policy'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterRightLinks.privacyPolicy')}
        </Text>
      </Link>
      <Link
        prefetch={false}
        href='/privacy/cookie-policy'
      >
        <Text textStyle={{ base: 'text-regular-xs', lg: 'text-medium-lg' }}>
          {t('DemuzoFooterRightLinks.cookiePolicy')}
        </Text>
      </Link>
    </>
  );
};

export const DemuzoFooterLogo = () => {
  return (
    <Link
      prefetch={false}
      href='/'
    >
      <Box
        width={{ base: '104px', lg: '168px' }}
        height={{ base: '25px', lg: '41px' }}
        position='relative'
      >
        <Image
          src='/images/logo-color.svg'
          alt='logo'
          fill={true}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
        />
      </Box>
    </Link>
  );
};

export const DemuzoFooterSocialLinks = () => {
  return (
    <>
      <Link
        prefetch={false}
        key='instagram'
        target='_blank'
        href='https://www.instagram.com/demuzo_official'
      >
        <IconInst />
      </Link>
      <Link
        prefetch={false}
        key='tiktok'
        target='_blank'
        href='https://www.tiktok.com/@demuzo_official'
      >
        <IconSocialTiktok />
      </Link>
      <Link
        prefetch={false}
        key='twitter'
        target='_blank'
        href='https://x.com/demuzo_official'
      >
        <IconSocialX />
      </Link>
      <Link
        prefetch={false}
        key='youtube'
        target='_blank'
        href='https://www.youtube.com/@demuzo_official'
      >
        <IconSocialYoutube />
      </Link>
      <Link
        prefetch={false}
        key='linkedin'
        target='_blank'
        href='https://www.linkedin.com/company/demuzo'
      >
        <IconSocialLinkedin />
      </Link>
      {/* Temp
      <Link
        prefetch={false}
        key='discord'
        target='_blank'
        href='https://discord.gg/VPRk6ZfX'
      >
        <IconSocialDiscord />
      </Link> */}
    </>
  );
};

export const DemuzoFooter = () => {
  return (
    <Footer
      logo={<DemuzoFooterLogo />}
      leftLinks={<DemuzoFooterLeftLinks />}
      rightLinks={<DemuzoFooterRightLinks />}
      socialLinks={<DemuzoFooterSocialLinks />}
    />
  );
};

export default DemuzoFooter;
