import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <path
      key='path-1'
      d='M6.72312 8H3.27688C3.12393 8 3 8.12799 3 8.28581V19.7142C3 19.8721 3.12393 20 3.27688 20H6.72312C6.87607 20 7 19.8721 7 19.7142V8.28581C7 8.12799 6.87607 8 6.72312 8Z'
      fill='currentColor'
    />,
    <path
      key='path-2'
      d='M5.501 2C4.12194 2 3 3.12127 3 4.49948C3 5.87831 4.12194 7 5.501 7C6.87898 7 8 5.87825 8 4.49948C8.00006 3.12127 6.87898 2 5.501 2Z'
      fill='currentColor'
    />,
    <path
      key='path-3'
      d='M16.3168 7C14.8452 7 13.7574 7.65001 13.0976 8.38857V7.60306C13.0976 7.43604 12.9658 7.30059 12.8032 7.30059H9.29437C9.13176 7.30059 9 7.43604 9 7.60306V19.6975C9 19.8646 9.13176 19.9999 9.29437 19.9999H12.9503C13.1129 19.9999 13.2447 19.8646 13.2447 19.6975V13.7135C13.2447 11.6971 13.7777 10.9115 15.1457 10.9115C16.6356 10.9115 16.754 12.1709 16.754 13.8173V19.6975C16.754 19.8646 16.8857 20 17.0484 20H20.7056C20.8682 20 21 19.8646 21 19.6975V13.0635C21 10.0652 20.4436 7 16.3168 7Z'
      fill='currentColor'
    />,
  ],
});

const IconSocialLinedin: FC<IconPropsWithSize> = ({
  size = 'lg',
  ...props
}) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconSocialLinedin);
