import { Image, Link } from '@chakra-ui/next-js';
import {
  Box,
  DrawerCloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  header: ReactNode;
  onCloseMenu: () => void;
  isMenuOpen: boolean;
};

export const MenuBurger: FC<Props> = ({
  onCloseMenu,
  children,
  isMenuOpen,
  header,
}) => {
  const t = useTranslations();

  return (
    <Box>
      <Box
        as={Modal}
        autoFocus={false}
        onClose={onCloseMenu}
        size='full'
        isOpen={isMenuOpen}
        variant='burgerStyle'
      >
        <ModalOverlay />
        <ModalContent
          bg='background.bg-black'
          p='20px'
        >
          <ModalHeader p={0}>
            <Flex
              direction='row'
              pb='40px'
            >
              <Link href='/'>
                <Image
                  src='/images/logo-white.svg'
                  alt='logo'
                  width={104}
                  height={25}
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              </Link>
              <DrawerCloseButton
                top={{ base: '10px', lg: '20px' }}
                right={{ base: '10px', lg: '20px' }}
              />
            </Flex>

            {header}
          </ModalHeader>

          <ModalBody
            as={'nav'}
            display='flex'
            flexDir='column'
            justifyContent='flex-end'
            gap='20px'
            p='40px 0 0'
          >
            {children}
          </ModalBody>

          <ModalFooter
            mt='20px'
            p='20px 0 0'
            justifyContent='start'
          >
            <Text
              textStyle='text-regular-xs'
              color='states.text&icon.disabled'
            >
              {t('MenuBurger.copyright')}
            </Text>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Box>
  );
};

export default MenuBurger;
