import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { ReactNode, useState } from 'react';

import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import Divider from '@ui-kit/molecules/divider/divider';

type Props = {
  logo: ReactNode;
  socialLinks: ReactNode;
  leftLinks: ReactNode;
  rightLinks: ReactNode;
};

export const Footer = ({ logo, socialLinks, rightLinks, leftLinks }: Props) => {
  const t = useTranslations();

  const [isVersionShow, setIsVersionShow] = useState(false);
  const handleShowVersion = () => {
    setIsVersionShow((prevState) => !prevState);
  };
  const isMobile = useIsMobile();

  return (
    <Box
      mx='auto'
      p={{ base: '20px', lg: '40px' }}
      bg='background.bg-primary'
      rounded='30px'
    >
      <Flex
        align={{ base: 'center', lg: 'flex-start' }}
        justify='space-between'
      >
        {logo}

        {!isMobile ? (
          <Flex
            gap='10px'
            minW='590px'
          >
            <Flex
              as='nav'
              flex={1}
              gap='15px'
              direction='column'
            >
              {leftLinks}
            </Flex>

            <Flex
              as='nav'
              flex={1}
              gap='15px'
              direction='column'
            >
              {rightLinks}
            </Flex>
          </Flex>
        ) : null}

        <Flex
          as='nav'
          gap={{ base: '10px', lg: '20px' }}
        >
          {socialLinks}
        </Flex>
      </Flex>

      {isMobile ? <Divider mt='40px' /> : null}

      {isMobile ? (
        <Flex
          gap={{ base: '10px', lg: '20px' }}
          mt='20px'
        >
          <Flex
            as='nav'
            flex={1}
            gap='15px'
            direction='column'
          >
            {leftLinks}
          </Flex>

          <Flex
            as='nav'
            flex={1}
            gap='15px'
            direction='column'
          >
            {rightLinks}
          </Flex>
        </Flex>
      ) : null}

      <Text
        mt={{ base: '20px', lg: '88px' }}
        textStyle={{ base: 'text-regular-xs', lg: 'text-regular-sm' }}
        color='states.text&icon.disabled-contrast'
      >
        {t.rich('Footer.copyrightApp', {
          version: process.env.NEXT_PUBLIC_APP_VERSION,
          build: process.env.NEXT_PUBLIC_HASH_COMMIT,
          hiddenWhenClick: (chunks) =>
            isVersionShow ? <span>{chunks}</span> : null,
          clickArea: (chunks) => (
            <span onClick={handleShowVersion}>{chunks}</span>
          ),
        })}
      </Text>
    </Box>
  );
};

export default Footer;
