'use client';
import { Box, Container, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import { ReactNode } from 'react';

import { usePlayList } from '@/features/audio-player';

type Props = {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
};

export const RootTemplate = ({ children, header, footer }: Props) => {
  const { currentTrack } = usePlayList();
  const router = useRouter();
  const isPlayerActive = currentTrack !== null;
  return (
    <Flex
      flexDirection='column'
      minH='100vh'
      pb={isPlayerActive ? { base: '80px', md: '140px' } : undefined}
    >
      {header ? (
        <Box
          position='sticky'
          top={0}
          zIndex='popover'
        >
          {header}
        </Box>
      ) : null}
      <Container
        flex={1}
        as='main'
        display='flex'
        flexDirection='column'
        maxWidth={{
          base: 'full',
          md: 'container.md',
          lg: 'container.lg',
          xl: 'container.xl',
        }}
        paddingInlineStart={{ base: 0, md: '20px', lg: '40px' }}
        paddingInlineEnd={{ base: 0, md: '20px', lg: '40px' }}
      >
        <Flex
          display='flex'
          flex={1}
          direction='column'
          position='relative'
        >
          {children}
        </Flex>
        {footer ? <Box mt='20px'>{footer}</Box> : null}
      </Container>
    </Flex>
  );
};

export default RootTemplate;
