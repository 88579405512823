import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper-bundle.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/shared/fonts/fonts.ts\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"preload\":true,\"subsets\":[\"latin\",\"cyrillic\"],\"weight\":[\"600\",\"500\",\"400\"],\"variable\":\"--font-ibm-mono\"}],\"variableName\":\"ibmPlexMono\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/shared/fonts/fonts.ts\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"preload\":true,\"subsets\":[\"latin\",\"cyrillic\"],\"weight\":[\"600\",\"500\",\"400\"],\"variable\":\"--font-ibm-sans\"}],\"variableName\":\"ibmPlexSans\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/shared/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":\"../ui-kit/assets/NaNMetrify-A-Latin-Wide-Bold/WOFF/NaNMetrifyAWide-Bld.woff2\",\"weight\":\"700\",\"style\":\"normal\",\"variable\":\"--font-metrify\"}],\"variableName\":\"naNMetrifyAWide\"}");
import(/* webpackMode: "eager" */ "/app/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/demuzo-footer/demuzo-footer.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/demuzo-header/demuzo-header.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/global-error-guard/global-error-guard.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/session-guard/session-guard.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/ui-kit/templates/root-template/root-template.tsx")