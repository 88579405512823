'use client';

import { useUnit } from 'effector-react';

import {
  $globalErrors,
  resetErrors,
  resolveErrorById,
} from '@/entities/global-error/model';

export const useGlobalErrors = () =>
  useUnit([$globalErrors, resolveErrorById, resetErrors]);
