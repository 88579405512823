'use client';

import { usePathname } from 'next/navigation';
import type { Session } from 'next-auth';
import { signIn, signOut } from 'next-auth/react';
import { ReactNode, useEffect } from 'react';

import { privateRoutesRegexp } from '@/shared/constants/private-routes';

export default function SessionGuard({
  children,
  session,
}: {
  children: ReactNode;
  session: Session | null;
}) {
  const path = usePathname();
  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      if (privateRoutesRegexp.test(path || '')) {
        signIn();
        return;
      }
      signOut(); //// https://jira.kode.ru/browse/DMZ-1004
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return <>{children}</>;
}
